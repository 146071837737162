import { Box, Tooltip } from '@chakra-ui/react';
import React from 'react';

import { IntercomIcon } from '../theme/icons';

export const BlockedIntercom = () => {
  return (
    <Box height={0} overflow="hidden" position="fixed" width={0}>
      <Box
        alignItems="center"
        bottom="20px"
        display="flex"
        height="48px"
        justifyContent="center"
        position="fixed"
        right="20px"
        sx={{ cursor: 'pointer', background: '#696969', borderRadius: '50%' }}
        width="48px"
      >
        <Tooltip
          bg="#f5f5f9"
          color="#000000de"
          label="Chat not working? Browser extension might be blocking it. Disable extensions and refresh the page."
          placement="left-start"
        >
          <IntercomIcon color="white" height="24px" width="24px" />
        </Tooltip>
      </Box>
    </Box>
  );
};
