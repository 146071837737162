import { Button, HStack, Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React, { ReactElement, ReactNode } from 'react';

import { goToLoginPage, goToScheduleDemo, goToSignUpPage } from '../common/commonFunctions';
import theme from '../theme';

const NavigationLink: React.FC<{ children: ReactNode; to: string; onClick?: () => void }> = ({ children, to, onClick }) => {
  return (
    <Link
      as={GatsbyLink}
      color="gray.500"
      css={`
        &:hover {
          text-decoration: none;
          color: ${theme.colors.brand.main};
        }
      `}
      fontSize={{ base: 'sm', md: 'md' }}
      fontWeight={600}
      onClick={onClick}
      to={to}
    >
      {children}
    </Link>
  );
};

function DesktopMenu({ withRates }: { withRates: boolean }): ReactElement {
  return (
    <HStack display={{ base: 'none', lg: 'flex' }} h="60px" justifyContent="flex-end" spacing="3">
      <HStack fontSize="md" justifyContent="flex-end" mr={{ base: 2, xl: 8 }} spacing="6">
        {withRates ? <NavigationLink to={`${process.env.GATSBY_APP_URL}/rates`}>Estimate Your Cost</NavigationLink> : null}
        <NavigationLink onClick={goToScheduleDemo} to="">
          Schedule a Demo
        </NavigationLink>
        <NavigationLink to="/reporters">For Court Reporters</NavigationLink>
      </HStack>
      <Button colorScheme="brand" onClick={goToLoginPage} size={{ base: 'xs', md: 'sm', lg: 'md' }} variant="ghost">
        Sign In
      </Button>
      <Button colorScheme="brand" onClick={goToSignUpPage} size={{ base: 'xs', md: 'sm', lg: 'md' }} variant="solid">
        Create Account
      </Button>
    </HStack>
  );
}

export default DesktopMenu;
