const getCanonicalHostName = (): string => {
  return process.env.GATSBY_CANONICAL_DOMAIN === 'localhost' ? '' : process.env.GATSBY_CANONICAL_DOMAIN || '';
};

export const goToCanonicalHostNameFromWrongDomain = (): void => {
  const { pathname, hostname, search, protocol } = window.location;
  const canonicalHostName = getCanonicalHostName();
  if (canonicalHostName && hostname !== canonicalHostName) {
    window.location.href = `${protocol}//${canonicalHostName}${pathname}${search}`;
  }
};
export const goToLoginPage = (): void => {
  window.location.href = `${process.env.GATSBY_APP_URL}/login`;
};

export const goToSignUpPage = (): void => {
  window.location.href = `${process.env.GATSBY_APP_URL}/sign-up`;
};

export const goToSignUpPageForExecutor = (): void => {
  window.location.href = `${process.env.GATSBY_APP_URL}/sign-up?user_type=executor`;
};

export const goToOurRatesPage = (): void => {
  window.location.href = `${process.env.GATSBY_APP_URL}/rates`;
};

export const goToScheduleDemo = (): void => {
  window.gtag && window.gtag('event', 'schedule_demo');
  window.location.href = 'https://calendly.com/trialbase/demo';
};

export const goToHomePage = (): void => {
  window.location.href = `${process.env.GATSBY_APP_URL}/`;
};

// eslint-disable-next-line import/no-unused-modules
export const goToUploadDepoNoticePage = (): void => {
  window.location.href = `${process.env.GATSBY_APP_URL}/dashboard/create-deposition`;
};

interface parseCookieResultType {
  [key: string]: string;
}

export const parseCookie = (str: string): parseCookieResultType => {
  if (!str) return {};

  const result: parseCookieResultType = {};

  return str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, result);
};
