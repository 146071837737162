import { createIcon } from '@chakra-ui/react';

export const SavingIcon = createIcon({
  displayName: 'SavingIcon',
  viewBox: '0 0 145 146',
  d: 'M0.119629 73.1651L19.2008 62.5959L5.61291 45.5221L27.2827 43.0663L21.2563 22.0868L42.2156 28.1191L44.6692 6.42842L61.7265 20.0294L72.2856 0.929871L82.8448 20.0294L99.9021 6.42842L102.356 28.1191L123.315 22.0868L117.289 43.0663L138.958 45.5221L125.37 62.5959L144.452 73.1651L125.37 83.7345L138.958 100.808L117.289 103.264L123.315 124.243L102.356 118.211L99.9021 139.902L82.8448 126.301L72.2856 145.4L61.7265 126.301L44.6692 139.902L42.2156 118.211L21.2563 124.243L27.2827 103.264L5.61291 100.808L19.2008 83.7345L0.119629 73.1651Z'
});

export const IntercomIcon = createIcon({
  displayName: 'IntercomIcon',
  viewBox: '0 0 28 32',
  d: 'M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z'
});
