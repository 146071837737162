import { Box, Button, Divider, HStack, IconButton, Link, VStack } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { IoMenu, IoCloseSharp } from 'react-icons/io5';

import { goToLoginPage, goToScheduleDemo, goToSignUpPage } from '../common/commonFunctions';
import theme from '../theme';

import Logo from './Logo';

const NavigationButton: React.FC<{ icon: ReactElement; onClick: () => void; label: string }> = ({ icon, onClick, label }) => {
  return (
    <IconButton
      aria-label={label}
      color={theme.colors.gray[500]}
      css={`
        &:hover {
          text-decoration: none;
          color: ${theme.colors.brand.main};
        }
      `}
      icon={icon}
      mr={2}
      onClick={onClick}
      size="lg"
      variant="chill"
    />
  );
};

const NavigationLink: React.FC<{ children: ReactNode; to: string; onClick?: () => void }> = ({ children, to, onClick }) => {
  return (
    <Link
      as={GatsbyLink}
      color="gray.500"
      css={`
        &:hover {
          text-decoration: none;
          color: ${theme.colors.brand.main};
        }
      `}
      fontSize="xl"
      fontWeight={600}
      onClick={onClick}
      to={to}
    >
      {children}
    </Link>
  );
};

function MobileMenu({ withRates }: { withRates: boolean }): ReactElement {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    if (isMenuVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isMenuVisible]);

  return (
    <Box display={{ base: 'flex', lg: 'none' }}>
      <NavigationButton
        icon={<IoMenu fontSize="xx-large" />}
        label="Open Menu"
        onClick={() => {
          setIsMenuVisible(true);
        }}
      />

      <VStack
        bgColor="white"
        // eslint-disable-next-line react/forbid-component-props
        className={isMenuVisible ? 'mobileMenuVisible' : 'mobileMenu'}
        fontSize="md"
        h="100vh"
        left="0"
        mr={10}
        overflowY="auto"
        position="fixed"
        px={{ base: '3', md: '6' }}
        spacing="6"
        top="0"
        w=" 100vw"
        zIndex={20}
      >
        <HStack alignItems="center" justifyContent="space-between" mb={3} w="full">
          <Logo onlyTitle />
          <NavigationButton
            icon={<IoCloseSharp fontSize="xx-large" />}
            label="Close Menu"
            onClick={() => {
              setIsMenuVisible(false);
            }}
          />
        </HStack>

        {withRates ? <NavigationLink to={`${process.env.GATSBY_APP_URL}/rates`}>Estimate Your Cost</NavigationLink> : null}
        <NavigationLink onClick={goToScheduleDemo} to="">
          Schedule a Demo
        </NavigationLink>
        <NavigationLink to="/reporters">For Court Reporters</NavigationLink>
        <Divider borderColor="#afb1b3" />
        <Button colorScheme="brand" onClick={goToLoginPage} size="lg" variant="ghost">
          Sign In
        </Button>
        <Button colorScheme="brand" onClick={goToSignUpPage} size="lg" variant="solid">
          Create Account
        </Button>
      </VStack>
    </Box>
  );
}

export default MobileMenu;
