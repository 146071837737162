import React, { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from '../ErrorFallback';

const ErrorBoundaryWrapper: FC<{ children: React.ReactElement }> = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.href = `https://${window.location.hostname}`;
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
