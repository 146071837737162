import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

import MainWrapper from './wrappers/MainWrapper';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export const ErrorFallback: FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  const isProd = process.env.NODE_ENV === 'production' ? true : false;
  if (!isProd) {
    // eslint-disable-next-line no-console
    console.error('--Error-- = ', error.stack);
  }

  return (
    <MainWrapper>
      <VStack bg="white" boxShadow="inner" flex={1} justifyContent="center" p={5} py={{ base: 8, lg: 14 }} spacing={5} w="full">
        <Heading fontSize="2xl" m={3}>
          Something went wrong:
        </Heading>
        <Text>{error.message}</Text>
        {!isProd && (
          <Box m={5}>
            <code>{error.stack}</code>
          </Box>
        )}
        <Button colorScheme="brand" onClick={resetErrorBoundary} size={{ base: 'xs', md: 'sm', lg: 'md' }} variant="solid" width="150px">
          Try Again
        </Button>
      </VStack>
    </MainWrapper>
  );
};
