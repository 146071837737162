import './styles.css';
import { extendTheme } from '@chakra-ui/react';

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)'
};

const theme = extendTheme({
  fonts: {
    heading: `Manrope, sans-serif, "Source Sans Pro"`,
    body: `Manrope, sans-serif, "Source Sans Pro"`
  },
  colors: {
    brand: {
      main: '#5564db',
      second: '#3D7FFF',
      50: '#f6f7fd',
      100: '#dcdff8',
      200: '#bdc3f1',
      300: '#98a1e9',
      400: '#838ee5',
      500: '#6673df',
      600: '#505ece',
      700: '#404ca6',
      800: '#36408c',
      900: '#272e65'
    }
  },
  components: {
    Table: {
      variants: {
        simple: {
          table: {
            fontSize: { base: 'xs', sm: 'sm', md: 'md', lg: 'lg' }
          },

          th: {
            fontSize: { base: 'xs', sm: 'sm', md: 'md', lg: 'lg' },
            borderColor: 'gray.500',
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 2,
            paddingRight: 2
          },
          td: {
            borderColor: 'gray.500',
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 2,
            paddingRight: 2
          }
        }
      }
    },

    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label': {
              ...activeLabelStyles
            },
            label: {
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
              fontWeight: 400,
              fontSize: 'sm'
            },
            input: {
              height: '50px'
            }
          }
        }
      }
    }
  }
});

export default theme;
