import type { GatsbyBrowser } from 'gatsby';
import React from 'react';

import ErrorBoundaryWrapper from './src/components/wrappers/ErrorBoundaryWrapper';
import { AppIntercomProvider } from './src/intercom/AppIntercomProvider';

/* eslint-disable import/no-unused-modules */
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <ErrorBoundaryWrapper>
      <AppIntercomProvider>{element}</AppIntercomProvider>
    </ErrorBoundaryWrapper>
  );
};
