import { Box, ChakraProvider, Flex } from '@chakra-ui/react';
import Aos from 'aos';
import React, { FC, useEffect } from 'react';

import { goToCanonicalHostNameFromWrongDomain } from '../../common/commonFunctions';
import theme from '../../theme';
import Footer from '../Footer';
import Header from '../Header';

const MainWrapper: FC<{ children: React.ReactNode; withRates?: boolean }> = ({ children, withRates = true }) => {
  useEffect(() => {
    goToCanonicalHostNameFromWrongDomain();
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1000
    });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Flex direction="column" sx={{ minHeight: '100vh' }}>
        <Header withRates={withRates} />
        <Box display="flex" flex={1} flexDirection="column" overflow="hidden">
          {children}
        </Box>
        <Footer withRates={withRates} />
      </Flex>
    </ChakraProvider>
  );
};

export default MainWrapper;
