import { Box, Flex, chakra, Container, GridItem, Image, SimpleGrid, Stack, Text, Link, useColorModeValue } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React, { ReactElement, ReactNode } from 'react';
import { HiOutlinePhone } from 'react-icons/hi';
import { MdOutlineMailOutline } from 'react-icons/md';
// @ts-ignore
import Obfuscate from 'react-obfuscate';

import logoImg from '../images/logo.svg';

const ListHeader: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Text fontSize={{ base: 'md', lg: 'lg' }} fontWeight="700" mb={0}>
      {children}
    </Text>
  );
};

const LinkText: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Text fontSize={{ base: 'xs', md: 'sm', lg: 'md' }} mt={0}>
      {children}
    </Text>
  );
};

const LogoBlock: React.FC = () => {
  return (
    <GridItem>
      <Box mr={4}>
        <Image height="16px" src={logoImg} width="90px" />
      </Box>
      <Text fontSize="sm" lineHeight="5">
        Trial Ready. Already.
        <br />© 2022-{new Date().getFullYear()} TrialBase, Inc.
        <br />
        CA CRF Lic. 225
        <br />
      </Text>
      <Text fontSize="xs">Version: {process.env.GATSBY_VERSION}</Text>
    </GridItem>
  );
};

const CompanyBlock: React.FC = () => {
  return (
    <Stack align="flex-start" order={{ base: 1, md: 2 }} spacing={1}>
      <ListHeader>Company</ListHeader>
      <Link as="div">
        <GatsbyLink to="/about">
          <LinkText>About</LinkText>
        </GatsbyLink>
      </Link>
      <Link as="div">
        <GatsbyLink to="/contact">
          <LinkText>Contact</LinkText>
        </GatsbyLink>
      </Link>
    </Stack>
  );
};

const RatesBlock: React.FC = () => {
  return (
    <Stack align="flex-start" order={{ base: 2, md: 3 }} spacing={1}>
      <ListHeader>Our Rates</ListHeader>
      <Link as="div">
        <GatsbyLink to={`${process.env.GATSBY_APP_URL}/rates`}>
          <LinkText>Estimate Your Cost</LinkText>
        </GatsbyLink>
      </Link>
    </Stack>
  );
};

const LegalBlock: React.FC = () => {
  return (
    <Stack align="flex-start" order={{ base: 2, md: 3 }} spacing={1}>
      <ListHeader>Legal</ListHeader>
      <Link as="div">
        <GatsbyLink to="/terms">
          <LinkText>Terms of Service</LinkText>
        </GatsbyLink>
      </Link>
      <Link as="div">
        <GatsbyLink to="/privacy">
          <LinkText>Privacy Policy</LinkText>
        </GatsbyLink>
      </Link>
    </Stack>
  );
};

const SupportBlock: React.FC = () => {
  return (
    <Stack align="flex-start" order={{ base: 2, md: 3 }} spacing={1}>
      <ListHeader>Support</ListHeader>
      <Flex alignItems="center" justifyContent="center">
        <Box fontSize={{ base: 'xs', md: 'sm', lg: 'md' }} mr={1}>
          <HiOutlinePhone />
        </Box>
        <chakra.p fontSize={{ base: 'xs', md: 'sm', lg: 'md' }} textAlign="center">
          <Obfuscate tel="(415) 513-7100" />
        </chakra.p>
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Box fontSize={{ base: 'xs', md: 'sm', lg: 'md' }} mr={1}>
          <MdOutlineMailOutline />
        </Box>
        <chakra.p fontSize={{ base: 'xs', md: 'sm', lg: 'md' }} textAlign="center">
          <Obfuscate email="support@trialbase.com" />
        </chakra.p>
      </Flex>
    </Stack>
  );
};

export default function Footer({ withRates }: { withRates: boolean }): ReactElement {
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.900')} boxShadow="inner" color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW="6xl" py={10}>
        {withRates ? (
          <SimpleGrid spacing={{ base: 4, md: 8 }} templateColumns={{ md: '1fr 3fr', base: '1fr' }}>
            <LogoBlock />
            <SimpleGrid spacing={{ base: 4, md: 8 }} templateColumns={{ lg: '1fr 1fr 1fr 1fr', base: '1fr 1fr' }}>
              <CompanyBlock />
              <RatesBlock />
              <LegalBlock />
              <SupportBlock />
            </SimpleGrid>
          </SimpleGrid>
        ) : (
          <SimpleGrid spacing={{ base: 4, md: 8 }} templateColumns={{ md: '2fr 1fr 1fr 1fr', base: '1fr 1fr' }}>
            <LogoBlock />
            <CompanyBlock />
            <LegalBlock />
            <SupportBlock />
          </SimpleGrid>
        )}
      </Container>
    </Box>
  );
}
