import React, { FC, useState, useEffect } from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

import { BlockedIntercom } from './BlockedIntercom';

const INTERCOM_APP_ID = process.env.GATSBY_INTERCOM_APP_ID!;

const Wrapper: FC<{ children: React.ReactElement }> = ({ children }) => {
  const { show } = useIntercom();
  const pathname = typeof window === `undefined` ? '' : window.document.location.pathname;

  useEffect(() => {
    if (pathname.includes('/contact/')) setTimeout(show, 1000);
  }, [pathname, show]);

  return children;
};

export const AppIntercomProvider: FC<{ children: React.ReactElement }> = ({ children }) => {
  const [intercomLoaded, setIntercomLoaded] = useState(false);

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      autoBoot
      onUnreadCountChange={() => {
        setIntercomLoaded(true);
      }}
    >
      <>
        <Wrapper>{children}</Wrapper>
        {!intercomLoaded && <BlockedIntercom />}
      </>
    </IntercomProvider>
  );
};
