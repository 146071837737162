import { chakra, Grid, GridItem } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

import DesktopMenu from './DesktopMenu';
import Logo from './Logo';
import MobileMenu from './MobileMenu';

function Header({ withRates }: { withRates: boolean }): ReactElement {
  return (
    <chakra.header bg="white" boxShadow="base" left="0" pos="sticky" px={{ base: '3', md: '6' }} top="0" w="full" zIndex={999}>
      <Grid gap={1} templateColumns={{ base: '1fr 1fr', lg: '1fr 2fr' }}>
        <GridItem>
          <Logo />
        </GridItem>
        <GridItem alignItems="center" display="flex" justifyContent="flex-end">
          <DesktopMenu withRates={withRates} />
          <MobileMenu withRates={withRates} />
        </GridItem>
      </Grid>
    </chakra.header>
  );
}

export default Header;
