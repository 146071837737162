import { Box, HStack } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

import logoImg from '../images/logo.svg';

const Logo: React.FC<{ onlyTitle?: boolean }> = ({ onlyTitle = false }) => {
  return (
    <HStack h="full" spacing={{ base: '5', md: '6', lg: '7' }}>
      <GatsbyLink to="/">
        <Box fontSize={{ base: 'lg', md: 'xl', lg: '28px' }} fontWeight="medium" py="2.5" width={{ base: '91px', md: '120px' }}>
          <img alt="TrialBase" loading="lazy" src={logoImg} />
        </Box>
      </GatsbyLink>
      {!onlyTitle && (
        <>
          <Box borderColor="blackAlpha.200 !important" borderLeft="1px" display={{ base: 'none', xl: 'block' }} h="full" />
          <Box color="brand.main" display={{ base: 'none', xl: 'block' }} fontSize={{ base: 'xs', md: 'sm', lg: 'md' }} fontWeight="normal">
            Trial Ready. Already.
          </Box>
        </>
      )}
    </HStack>
  );
};

export default Logo;
